<template>
  <div v-if="isSuccess && data">
    <div
      v-if="['other', 'canceled'].includes(data.status.name)"
      class="tw-mt-8 tw-rounded-lg tw-bg-white tw-p-6 tw-drop-shadow"
    >
      <div class="tw-flex tw-gap-2">
        <div>
          <Heading :level="5" class="first-letter:tw-uppercase">
            {{ data.status.name === 'other' ? 'awaiting status' : canceledMessage }}
          </Heading>
          <Subheading class="tw-block sm:tw-hidden md:tw-block">
            {{ data.status.description }}
          </Subheading>
        </div>
      </div>
    </div>
    <div v-else class="tw-mt-8 tw-rounded-lg tw-bg-white tw-p-6 tw-drop-shadow">
      <div class="tw-flex tw-place-content-between tw-gap-2">
        <div>
          <Heading :level="5" class="first-letter:tw-uppercase">
            {{ data.status.name }}
          </Heading>
          <Subheading class="md:tw-line-clamp-1 lg:tw-line-clamp-none">
            {{ truncate(data.status.description, { length: 65 }) }}
          </Subheading>
        </div>
        <div>
          <T2Button
            class="tw-border-0 tw-bg-gray-100 !tw-py-2 tw-px-4 tw-font-normal tw-text-gray-800"
            @click="openTrackerDrawer"
          >
            View
          </T2Button>
        </div>
      </div>
      <ul class="tw-mt-6 tw-flex tw-items-center">
        <li data-testid="start-dot" class="tw-rounded-full tw-bg-gray-800 tw-p-1.5">
          <SquaresIcon class="!tw-text-xl tw-text-white" />
        </li>

        <template v-for="index of data.statuses.length - 2" :key="index">
          <li
            data-testid="half-step"
            class="tw-inline-block tw-h-1 tw-grow tw-bg-gray-200"
            :class="index <= data.status.index! + 1 ? 'tw-bg-gray-800' : ''"
          />
          <li
            data-testid="half-step"
            class="tw-inline-block tw-h-1 tw-grow tw-bg-gray-200"
            :class="index <= data.status.index! ? 'tw-bg-gray-800' : ''"
          />
          <li
            data-testid="step"
            class="tw-relative tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-bg-gray-200"
            :class="index <= data.status.index! ? 'tw-bg-gray-800' : ''"
          >
            <div
              v-if="index <= data.status.index!"
              class="tw-absolute tw-left-[.375rem] tw-top-[.375rem] tw-inline-block tw-h-1 tw-w-1 tw-rounded-full tw-bg-white"
            />
          </li>
        </template>

        <li
          data-testid="half-step"
          class="tw-inline-block tw-h-1 tw-grow tw-bg-gray-200"
          :class="data.status.index! >= data.statuses.length - 2 ? 'tw-bg-gray-800' : ''"
        />
        <li
          data-testid="half-step"
          class="tw-inline-block tw-h-1 tw-grow tw-bg-gray-200"
          :class="data.status.index === data.statuses.length - 1 ? 'tw-bg-gray-800' : ''"
        />

        <li
          data-testid="end-step"
          class="tw-rounded-full tw-bg-gray-200 tw-p-1.5"
          :class="data.status.index === data.statuses.length - 1 ? 'tw-bg-gray-800' : ''"
        >
          <BanknotesIcon
            class="!tw-text-xl"
            :class="
              data.status.index === data.statuses.length - 1 ? 'tw-text-white' : 'tw-text-gray-800'
            "
          />
        </li>
      </ul>
      <div
        v-if="data.status.nextStatus"
        class="tw-flex tw-pt-4 tw-text-base tw-font-normal tw-text-gray-500"
      >
        Up next:&nbsp;
        <span class="first-letter:tw-uppercase">{{ data.status.nextStatus }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import T2Button from '@torticity/components/src/T2Button.vue'
import { MatterType } from '@torticity/types'
import truncate from 'lodash/truncate'

import BanknotesIcon from '~icons/heroicons-solid/banknotes.svg'
import SquaresIcon from '~icons/heroicons-solid/square-2-stack'

const { isSuccess, data } = useClaimStatusQuery()

const { $auth } = useNuxtApp()

const open = useTrackerDrawer()

const analytics = useAnalytics()

const canceledMessage = computed(() => {
  return $auth.user?.lead?.matterType === MatterType.AUTO_ACCIDENT
    ? 'this claim has been canceled'
    : 'this claim has been suspended'
})

function openTrackerDrawer() {
  analytics.track('ElementViewed', {
    elementType: 'claim tracker',
    text: data?.value?.status.name,
  })
  analytics.trackElementClicked({ elementType: 'button', text: 'View' })
  open.value = true
}
</script>
